import React, { useState, useEffect, CSSProperties } from 'react';
import { Masonry } from 'react-masonry';

const GridComponent: React.FC = () => {
  // Estado para manejar la altura dinámica de las cajas
  const [boxHeights, setBoxHeights] = useState<{ large: number; small: number }>({
    large: 550,
    small: 275,
  });

  // Función para ajustar las alturas según el tamaño de la ventana
  const adjustBoxHeights = () => {
    if (window.innerWidth < 768) {
      setBoxHeights({ large: 280, small: 140 });
    } else if (window.innerWidth < 1200) {
      setBoxHeights({ large: 400, small: 200 });
    } else {
      setBoxHeights({ large: 550, small: 275 });
    }
  };

  // Configura el evento `resize` en `useEffect`
  useEffect(() => {
    // Calcula la altura inicial
    adjustBoxHeights();

    // Ajusta las alturas cuando la ventana cambia de tamaño
    window.addEventListener('resize', adjustBoxHeights);

    // Limpia el evento al desmontar el componente
    return () => window.removeEventListener('resize', adjustBoxHeights);
  }, []);

  // Define los estilos de las cajas usando `boxHeights`
  const boxes: CSSProperties[] = [
    { width: '40%', height: boxHeights.large },
    { width: '20%', height: boxHeights.small },
    { width: '40%', height: boxHeights.large },
    { width: '20%', height: boxHeights.small },
    { width: '60%', height: boxHeights.large },
    { width: '40%', height: boxHeights.small },
    { width: '20%', height: boxHeights.small },
    { width: '20%', height: boxHeights.small },
    { width: '20%', height: boxHeights.large },
    { width: '40%', height: boxHeights.large },
    { width: '40%', height: boxHeights.large },
    { width: '100%', height: boxHeights.large },
  ];

  return (
    <section className="masonry">
      <div className="container">
        <Masonry>
          {boxes.map((box, index) => (
            <div key={index} style={box} className="box-col">
              <div className="box">
                {index}
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </section>
  );
};

export default GridComponent;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderComponent from '../../components/shared/header/header.component';
import { UserInformation } from '../../types/vivaaerobus';
import GridComponent from '../../components/grid/grid.component';



const HomeScreen = () => {
 

  useEffect(() => {
    window.dataLayer.push({
      event: "ev_ux",
      category: "lp_desempacando2023",
      action: "first_step",
      label: "open"
    });
    //console.log('INIT', window.dataLayer.push)
  }, []);

  return (
    <>
       <HeaderComponent />
       <section className="intro-section">
          <div className="container text-center">
            <p className="text-green500 text-64 font-700">#nombre</p> 
            <h1 className="text-white text-120 intro-section__title">Destata tu <strong className="text-green300">2024</strong></h1>
          </div>
       </section>
       <GridComponent />
    </>
  );
};

export default HomeScreen;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/App.scss';
import HomeScreen from './pages/Home/home.screen';

setDefaultOptions({ locale: es });

function App() {
  return (
    <main className="app-main">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/:customerId/:identityId" element={<HomeScreen />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
